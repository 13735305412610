
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/

$sans-serif: Arial,"Arial CE",Helvetica,"Helvetica CE",sans-serif;
$font: TekoWeb;

$color-base: #444444;       //základní barva textu
$color-base-link: #232323;  //základní barva odkazů
$color-main: #460432;       //

$hlavniobsahpozadi: #f3f3f3;
$hlavniobsahlinky: #444444;

/************************/


/*************************
* PROMĚNNÉ KOMPONENT
*************************/

/** TYPOGRAFIE **/

$h2-size: 60px;
$h3-size: 28px;
$h4-size: 21px;

/** FORMULÁŘE **/

$form-padding:        21px 36px 23px 36px;   // odsazení formulářů
$format-height:       35px;                  // výška běžných inputů a selectů ve formulářích
$format-main-height:  44px;                  // výška hlavního inputu ve formuláři
$btn-height:          35px;                  // výška tlačítek ve formulářích

/** TABULKY **/

$b2-background: #ffffff;
$b1-background: #ebebeb;
$th-background: #352d39;
$th-text:       #ffffff;

/** SEZNAMY **/

$document-link-color: $aktuality1nadpisy;        // barva odkazů ve výpisu dokumentů
$list-top-level-link-color: $aktuality1nadpisy;  // barva velkých odkazů v první úrovni seznamů

/** AKCE **/

$event-day-size: 35px;              // velikost písma v cedulce - den 
$event-month-size: 16px;            // velikost písma v cedulce - den 
$event-link-color: $akcenadpisy;        // barva odkazů ve výpisu akcí 
$event-day-background: $bunkyTabulekAktualni;      // pozadí dne cedulky v kalendáři
$event-day-text: $odkazyTabulekAktualni;         // text dne cedulky v kalendáři
$event-month-background: $bunkyTabulekOstatni; // pozadí měsíce cedulky v kalendáři
$event-month-text: $odkazyTabulekOstatni;       // text měsíce cedulky v kalendáři

/** KALENDÁŘE **/

$cal-cell-padding: 7px; //padding buňky - top a bottom

$cal-title-month-background: $bunkyTabulekAktualni;      //pozadí buňky v měsíci
$cal-title-month-text: $odkazyTabulekAktualni;            //text buňky v měsíci
$cal-title-notmonth-background: $bunkyTabulekOstatni;   //pozadí buňky mimo měsíc
$cal-title-notmonth-text: $odkazyTabulekOstatni;         //text buňky mimo měsíc
$cal-title-current-border: #000000;        //barva rámečku aktuálního dne

$cal-hover-background: $bunkyTabulekOstatni;            //pozadí buňky po najetí myši
$cal-hover-text: $odkazyTabulekOstatni;                  //text buňky po najetí myši

$cal-sub-month-background: $bunkyTabulekAktualni;
$cal-sub-month-text: $odkazyTabulekAktualni;
$cal-sub-notmonth-background: $bunkyTabulekOstatni;
$cal-sub-notmonth-text: $odkazyTabulekOstatni;
$cal-sub-current-border: $cal-title-current-border;
$cal-sub-selected-background: #B0030C;
$cal-sub-selected-text: #ffffff;

/** KALENDÁŘE **/

$menu-tablet-size:  800px;     // hranice mezi vyjížděcím/statickým menu
$menu-bg:           #ffffff; // pozadí menu
$menu-link-color:   $aktuality1nadpisy; // barva odkazů a písma v menu
$menu-topbar-bg:    $tlacitkapozadi;    // barva horní lišty značící aktuální umístění 
$menu-topbar-text:  $tlacitkatext; // text a ikony v horní liště
$menu-trigger-bg:   $tlacitkapozadi;   // barva pozadí tlačítka pro otevření menu 
$menu-trigger-text: $tlacitkatext; // text v tlačítku
$menu-active-bg:    transparent;  // pozadí aktivní položky mobilního menu
$menu-active-text:  inherit;  // text aktivní položky mobilního menu

/** ZÁLOŽKY **/

$tab-background: turquoise;           //pozadí záložky
$tab-text: #433923;                   //text záložky
$tab-selected-background: bisque;     //pozadí aktivní záložky
$tab-selected-text: #000000;          //text aktivní záložky

$tab-sub-background: turquoise;       //pozadí záložky - podstránky
$tab-sub-text: #433923;               //text záložky - podstránky
$tab-sub-selected-background: bisque; //pozadí aktivní záložky - podstránky
$tab-sub-selected-text: #000000;      //text aktivní záložky - podstránky

/************************/