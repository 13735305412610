
/***************************************
*	POČASÍ - OPENWEATHERMAP - MAX 5 DNÍ
***************************************/

.owm {
	text-align: left;
	list-style-type: none;
	margin: 0 0 20px 0;
	padding: 0;
	font-size: rem(16px);

	span[class*="owm"] {
		display: block;
	}

	.owmIcon {
		padding: .5em 0;
	}

	li {
		display: inline-block;
		padding: 0;
		margin: 0 1em;
	}

	&.owm-1,
	&.owm-2,
	&.owm-3 {
		li {
			.owmDateDayName {
				display: none;
			}
		}
	}

	&.owm-4,
	&.owm-5 {
		li {
			.owmDateDay {
				display: none;
			}
		}
	}

	@for $i from 1 through 5 {
		&.owm-#{$i} li {
			@include limit-items($i);
		}
	}

	.owmTempMax {
		font-size: rem(19px);
	}

	img{ 
		width: auto;
		height: auto;
		max-width: 48px;
	}
}


#pocasi a {
	padding-top: 0;
	padding-left: 0!important;
}