/*oE (vsechny stranky + editor)*/
 
@include font($font);
$font-base-px: 16px;
$font-base: get-base-font-size-percentage($font-base-px) !global;
 
html{
line-height: ratio($font-base-px, 22px, float);
font-family: $sans-serif; 
font-size: 18px;
@include bp("> 600px") {
font-size: $font-base-px;
}
}
 
body.editor {
background-color: #FFFFFF;
}
 
.editor {
h3 {
font-weight: normal;
margin: 10px 0;
padding: 0;
font-family: $font;
line-height: 1.2;
}
}
 
body,
.editor {
font-size: 1em;
}
 
body,
.automat {
background-color:$hlavniobsahpozadi;
color: $color-base;
}
 
@include link {
color:$bileplochyodkazy;
text-decoration: underline;
}
 
@include link-over {
text-decoration: none;
}
 
hr,
hr.oddelovac {
color: $hlavniobsahlinky;
background-color: $linky;
}
 
body.editor h3,
#celek .editor h3 { 
font-size: rem(40px); 
font-family: $font;
line-height: normal;
font-weight: normal;
}
 
body,
input,
button,
textarea,
select {
font-family: $sans-serif;
}
 
.zvyrazneni,
.vystraha,
.strlistovani strong {
color: #C00000;
background-color: #ffffff;
padding: 0 3px;
}
 
.vystraha {
border-radius: 3px;
}
.zvyrazneni2 {
color: #ffffff;
background-color: #C00000;
padding: 0 2px;
}
 
strong.zprava {
font-size: 1.1em;
}
 
img {
font-size: rem(12px);
max-width: 100%;
height: auto;
font-family: $sans-serif;
}
