
/******************
* MODULY
******************/

#uvod {
	overflow: hidden;
	margin: 0 0 30px 0;
	padding-top: 30px;
	color: $upozornenitext;

	&.editor h3 {
		color: $upozorneninadpisy;
		font-size: rem(45px);
	}

	@include link {
		color: $upozorneniodkazy;
	}
}

.wrapper.wrapper-uvod {
	background-color: $upozornenipozadi;
	margin-bottom: 30px;
}


#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}


#mbannery {
	font-size: rem(14px);
	padding: 30px 0;
	margin: 0;
	color: #000000;
	background: #ffffff;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

#jmeniny {
	font-size: rem(14px);
	display: none;

	@include bp("> 600px") {
		display: inline-block;
		
		* {
			display: inline;
		}
	}
}

#kontakt {
	address {
		display: block;
		font-style: normal;
		margin: 0 0 15px 0;
	}
	
	strong {
		font-weight: bold;
	}

	.iko-link {
		&::before {
			display: inline-block;
			width: 47px;
			height: 47px;
			vertical-align: middle;
			margin-right: 18px;
		}

		&.iko-napiste::before {
			content: url(images/iko_napiste.svg);
		}

		&.iko-mapa::before {
			content: url(images/iko_mapa.svg);
		}
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			border-radius: 3px;
			background-color: $vysokapatasymboly;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}
}
