
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/

/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/

$using-autoprefixer: true !global; // Pokud true, mixiny nebudou vypisovat prefixy

/*********************************/

@import "ecco";

@import "barvy2";
@import "vars";
@import "typografie";
@import "barvy1";

@import "komponenty/templaty";

@import "layout/styly";
@import "layout/zahlavi";

@import "komponenty/seznamy";
@import "komponenty/formulare";
@import "komponenty/tabulky";
@import "komponenty/galerie";
@import "komponenty/zalozky";
@import "komponenty/moduly";
@import "komponenty/kalendare";
@import "komponenty/menu";
@import "komponenty/pocasi";

//Odkomentovat, pokud bude potřeba:
//@import "komponenty/liteslider";
@import "komponenty/gte";
//@import "komponenty/diskuze";

// Pro debugování, před zveřejněním zakomentovat:
//@import "ecco-debug";
