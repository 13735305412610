/*oE (vsechny stranky)*/
$zahlavipozadi: #EEEEEE;
$zahlavitext: #272727;
$nazev: #206CAC;
$dodatek: #272727;
$zahlaviodkazy: #272727;
$vyhledavaniobrys: #115898;
 
$hlavninadpisy: #204998;
 
$menupozadi: #115898;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #B7DF2B;
$menutlacitkatext: #000000;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #EEEEEE;
$upozorneninadpisy: #024989;
$upozornenitext: #000000;
$upozorneniodkazy: #024989;
 
$aktuality1nadpisy: #2067A7;
$linky: #B7DF2B;
$bileplochyodkazy: #2067A7;
 
$deskalinky: #B7DF2B;
 
$kalendarakcipozadi: #EEEEEE;
$akcehlavninadpis: #204998;
$akcenadpisy: #204998;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #3C4146;
$odkazyTabulekAktualni: #FFFFFF;
$bunkyTabulekOstatni: #FFFFFF;
$bunkyTabulekAktualni: #115898;
$akcetlacitkapozadi: #C6EE3A;
$akcetlacitkatext: #000000;
$akceodkazy: #05297A;
 
$formularepozadi: #FFFFFF;
$formularetext: #272727;
$formularenadpis: #204998;
$polepozadi: #FFFFFF;
$poletext: #272727;
$poleobrysy: #6AD3FF;
 
$tlacitkapozadi: #C6EE3A;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #115898;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #FFFFFF;
$vysokapatanadpisy: #FFFFFF;
$vysokapatasymboly: #B7DF2B;
 
$patapozadi: #EEEEEE;
$patatext: #000000;
$pataodkazy: #115898;
